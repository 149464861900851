<template>
  <div class="content-preguntas-frecuentes" style="width: 100%">
    <div class="container-max-width">
      <div class="col-12 col-md-5 col-lg-6 col-xl-4 p-3 p-lg-4 p-xl-5">
        <h2>Explora las dudas más frecuentes</h2>
        <p>
          En este espacio podrás resolver cualquier duda que tengas sobre
          nuestros procesos, productos y compras en nuestra tienda Online,
          Clover Colombia.
        </p>
      </div>
      <div class="col-12 col-md-7 col-lg-6 col-xl-8 p-3 p-lg-4 p-xl-5">
        <div v-for="(data, index) in dataPolitics[6].items" :key="index">
          <acoordeon-preguntas :data="data" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AcoordeonPreguntas from '@/components/Templates/AcoordeonPreguntas'
export default {
  components: { AcoordeonPreguntas },
  data() {
    return {
      dataPolitics: [],
    }
  },
  async mounted() {
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/layout/get_footer',
    })
      .then(response => {
        this.dataPolitics = response.data.paginas
        document.title = 'Preguntas frecuentes | Clover Tienda Online'
      })
      .catch(error => {
        console.log(error)
      })
  },
}
</script>
