<template>
  <div class="conatiner-acoordeon-preguntas-frecuentes">
    <div
      class="container-title-preguntas-frecuentes"
      :style="
        isSelect ? 'background: #ebeeff; border-bottom: 1px solid #fafafa' : ''
      "
      @click="isSelect = !isSelect"
    >
      {{ question.titulo }}
      <svg
        id="Shopicons_Light_ArrowDown"
        :style="isSelect ? 'transform: rotate(-180deg)' : ''"
        class="ms-2"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 48 48"
      >
        <path
          id="Trazado_4825"
          data-name="Trazado 4825"
          d="M0,0H48V48H0Z"
          fill="none"
        />
        <g id="Shopicon">
          <g id="Grupo_5515" data-name="Grupo 5515">
            <path
              id="Trazado_4826"
              data-name="Trazado 4826"
              d="M24,30.585,8.707,15.292,7.293,16.706,24,33.413,40.707,16.706l-1.414-1.414Z"
            />
          </g>
        </g>
      </svg>
    </div>
    <div
      class="container-content-preguntas-frecuentes"
      :style="
        isSelect
          ? 'height: auto; background: #ebeeff;'
          : 'max-height: 0px; padding: 0;'
      "
    >
      <div v-html="question.descripcion"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    question: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      isSelect: false,
    }
  },
}
</script>
